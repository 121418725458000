import { createContext } from 'react';
import { SharedData } from './types/contextTypes';

const defaultSharedData: SharedData = {
  sessionId: '',
  userSub: '',
  auth0User: undefined,
  csvUrl: '',
  updateCSVurl: () => {},
  uploadedCSVName: '',
  updateUploadedCSVName: () => {},
  uploadedPDFName: '',
  updateUploadedPDFName: () => {},
  selectedVariable: '',
  updateSelectedVariable: () => {},
  availableLLM: ['gpt-4', 'cohere'],
  selectedLLM: 'gpt-4',
  updateSelectedLLM: () => {},
  selectedIndustry: '',
  updateSelectedIndustry: () => {},
  businessProblem: '',
  updateBusinessProblem: () => {},
};

const SharedDataContext = createContext<SharedData>(defaultSharedData);

export default SharedDataContext;
