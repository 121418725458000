import { createContext } from 'react';
import { AnalysisCompletionFlags } from './types/contextTypes';

const defaultAnalysisCompletionFlags: AnalysisCompletionFlags = {
  isDescriptiveStatsDone: false,
  updateIsDescriptiveStatsDone: () => {},
  isAutoMLAnalysisDone: false,
  updateIsAutoMLAnalysisDone: () => {},
  isLinearModelDone: false,
  updateIsLinearModelDone: () => {},
  recommendations: '',
  updateRecommendations: () => {},
  recommendationsUUID: '',
  updateRecommendationsUUID: () => {},
  problemType: '',
  updateProblemType: () => {},
};

export const AnalysisCompletionFlagsContext =
  createContext<AnalysisCompletionFlags>(defaultAnalysisCompletionFlags);

export default AnalysisCompletionFlagsContext;
