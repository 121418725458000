import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

const StyledAvatar = styled(Avatar)(() => ({
  height: '2rem',
  width: '2rem',
  color: 'white',
  fontSize: '1rem',
}));

export const UserAvatar = styled(StyledAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  fontSize: '1rem',
}));

export const ScavAvatar = styled(StyledAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: `1px solid ${theme.palette.primary.main}`,
  color: 'theme.palette.text.primary',
  fontSize: '1rem',
}));
