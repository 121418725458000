import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StyledH1 = styled(Typography)(() => ({
  padding: '5px 0',
}));
StyledH1.defaultProps = {
  variant: 'h1',
};

export const StyledH2 = styled(Typography)(() => ({
  padding: '5px 0',
}));
StyledH2.defaultProps = {
  variant: 'h2',
};

export const StyledH3 = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '5px 0 20px 0',
}));
StyledH3.defaultProps = {
  variant: 'h3',
};

export const StyledH4 = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '5px 0',
}));
StyledH4.defaultProps = {
  variant: 'h4',
};

export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '16px',
  whiteSpace: 'pre-wrap',
  padding: '5px 0',
  lineHeight: 'normal',
  letterSpacing: 'normal',
}));
StyledText.defaultProps = {
  variant: 'body1',
};

export const StyledCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 'light',
}));
StyledText.defaultProps = {
  variant: 'caption',
};

export const StyledH1WithDivider = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '18px',
  borderBottom: `0.01px solid ${theme.palette.divider}`,
  padding: '5px 0',
}));
StyledH1WithDivider.defaultProps = {
  variant: 'h1',
};

export const StyledUploadNote = styled(Typography)(({ theme }) => ({
  margin: '2% 0 0 25%',
  paddingLeft: '15px',
  color: theme.palette.text.primary,
}));
