import { styled } from '@mui/material/styles';
import Box from '@mui/system/Box';

export const StyledBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '1rem 1rem 1rem 0 ',
}));

export const StyledBoxPaper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '1rem 1rem 1rem 0 ',
}));
