import { createContext } from 'react';
import { ChatInitialization } from './types/contextTypes';

const defaultChatInitialization: ChatInitialization = {
  isChatInitialized: false,
  updateIsChatInitialized: () => {},
};

export const ChatInitializationContext = createContext<ChatInitialization>(
  defaultChatInitialization,
);

export default ChatInitializationContext;
